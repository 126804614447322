class PermissionsManager {
  constructor () {
    this.hasCameraPermission = false;
    this.hasNotificationPermission = false;
  }

  showPermissionsModalIfNeeded() {
    // console.log(this.hasCameraPermission, this.hasNotificationPermission)
    if ((this.hasCameraPermission == false || this.hasNotificationPermission == false)) {
      $('#permissionslModal').modal('show');
    } else {
      $('#permissionslModal').modal('hide');
    }
  }

  requestCameraPermission(baseLineImageWidth, baseLineImageHeight, baselineVideo) {
    navigator.getUserMedia = ( navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia);
    navigator.getUserMedia({
        video: {
          facingMode: 'user',
          width: baseLineImageWidth,
          height: baseLineImageHeight,
        },
        audio: false,
      },
      (stream) => {
        this.hasCameraPermission = true;
        this.requestNotificationPermission();
        // if (navigator.mozGetUserMedia && 0) {
        //   baselineVideo.mozSrcObject = stream;
        // } else {
        //   // const vendorURL = window.URL || window.webkitURL;
        baselineVideo.srcObject = stream;
        // }
        baselineVideo.play();

      },
      (err) => {
        this.showPermissionsModalIfNeeded();
        console.error('An error occured! ' + err);
      }
    );
  }

  requestNotificationPermission() {
    Notification.requestPermission(permission => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        this.hasNotificationPermission = true;
        this.showPermissionsModalIfNeeded();
      } else {
        // LU - right now not checking for notification permission until later
        this.hasNotificationPermission = true;
        this.showPermissionsModalIfNeeded();
      }
    });
  }
}

let permissionsManager = new PermissionsManager();

export default permissionsManager;
