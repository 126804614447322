import redPanda from './assets/redPanda.png';
import permissionsManager from './permissionsManager';

class PostureNotification {

  constructor() {
    this.permission = false;
    this.delay = 10000;
    this.blockNotif = false;
    this.badPostureConfig = {
      icon: redPanda,
      title: 'Bad Posture',
      message: 'Please do not slouch.'
    };
    this.notificationIntervalId = null;
    this.notification = null;
  }

  generateNotif() {
    this.notification = new Notification(
      this.badPostureConfig.title,
      {
        body: this.badPostureConfig.message,
        icon: this.badPostureConfig.icon
      }
    );
  }

  showNotification(data) {
    const currentStatus = data.status;

    if (this.notificationIntervalId == null && currentStatus == 'BAD' && this.blockNotif == false) {
      if (permissionsManager.hasNotificationPermission) {
        this.generateNotif();
        this.blockNotif = true;
      }
      this.notificationIntervalId = setTimeout(() => {
        this.blockNotif = false;
        this.notificationIntervalId = null;
      }, this.delay);
    }
  }

}
let postureNotifier = new PostureNotification();
export default postureNotifier;
