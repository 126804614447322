import * as posenet from '@tensorflow-models/posenet';
import {
  drawKeypoints,
  drawSkeleton,
} from './demo_util';
import postureNotifier from './notifications'
import permissionsManager from './permissionsManager'
/** ************************************************************
 * Variable declarations and configurations values section.
 * **************************************************************/
const posnetMultiplier = 0.75;
const estimationParams = {
  outputStride: 16,
  imageScaleFactor: 0.5,
  flipHorizontal: true,
  minPoseConfidence: 0.1,
  minPartConfidence: 0.5,
};

let detectPoseInRealTime = false;

let poseComparisonThreshold = {
  face: {
    elementId: 'faceThreshold',
    displayElementId: 'faceThresholdPlaceHolder',
    value: 20.0,
  },
  shoulder: {
    elementId: 'shoulderThreshold',
    displayElementId: 'shoulderThresholdPlaceHolder',
    value: 20.0,
  },
  faceAndShoulder: {
    elementId: 'faceShoulderThreshold',
    displayElementId: 'faceShoulderThresholdPlaceHolder',
  },
  tilt: {
    value: 20.0,
  },
  //tilt value updates are currently tied to tilt.value = inflate(face.value);
};

const maxThreshold = 50;
const multiplier = 10;

const poseMovementObj = {
  face: {
    leftEye: {},
    rightEye: {},
    leftEar: {},
    rightEar: {},
    nose: {},
  },
  shoulder: {
    leftShoulder: {},
    rightShoulder: {},
  },
};

let net;
const baseLineImageWidth = 600;
let baseLineImageHeight = 500;

let baselineModelOutputs;

let streaming = false;

let baselineVideo = null;
let baselineCanvas = null;
let takeBaselinePhotoBtn = null;

const movementResultDivId = 'movement-result-text';
const actualDisplayDivId = 'actual';
const actualTriggerDivId = "actualTrigger";
const baselineDisplayDivId = 'baseline';
const shoulderResultDivId = 'shoulder-results';
const tiltResultDivId = 'tilt-results';
const faceResultDivId = 'face-results';
const timerDivId = 'countdown-timer';
const minutesDivId = 'minutes';
const sessionStatsId = 'sessionStats';
const sessionSettingsId = 'sessionSettings';

const waitTimeForFalseDetection = 0.1; // Secs.

/** *************************
 * Pose detection timer values
 * *************************/
const DETECTION_INTERVAL_SLOW = 500; // 1.2s (Run detection every 1.2 after seconds when user is off the webApp)
const DETECTION_INTERVAL_FAST = 175; // 200ms (Run detection 5 times a seconds when user is on the webApp)
let posDetectionInterval = DETECTION_INTERVAL_FAST; // (default) When user is on the webApp
let posDetectIntervalId;

/** *************************
 * Timer Variables/Constants
 * *************************/
const DEFAULT_TIMEOUT_DURATION = 15 * 60 + 1; // (in seconds) 15 minutes
const timeoutMessage = 'Session timeout! Time for some rest.';
let countdownTime = DEFAULT_TIMEOUT_DURATION;
let countdownIntervalId;

/** *************************
 * Set global elements by ID
 * *************************/
let movementResultText = document.getElementById(movementResultDivId);
let actualDiv = document.getElementById(actualDisplayDivId);
let actualTriggerDiv = document.getElementById(actualTriggerDivId);
let baselineDiv = document.getElementById(baselineDisplayDivId);
let timerDiv = document.getElementById(timerDivId);
let minutesDiv = document.getElementById(minutesDivId);
let sessionStatsDiv = document.getElementById(sessionStatsId);
let sessionSettingsDiv = document.getElementById(sessionSettingsId);

/**
 * Gets and sets the threshold value in poseComparisonThreshold
 * @param {boolean} initial Set to true if this is the first time
 *  updateThresholdValue is called. Sets the range slider elements
 *  with the default value.
 * @param {boolean} overrideFaceAndShoulder Determines if the same value
 *  should be used for face and shoulder, only set to true if the
 *  faceAndShoulder value is changed.
 */
const updateThresholdValue = (
  initial = false, overrideFaceAndShoulder = false
) => {
  const inflate = (value, inflator = multiplier) => {
    return value * inflator;
  };

  const format = (value) => {
    return Number(value).toFixed(0);
  };

  const deflate = (value, deflator = multiplier) => {
    return format(value / deflator);
  };

  const updateRangeSliderValues = (sliderType, value) => {
    document.getElementById(
      poseComparisonThreshold[sliderType]
      .elementId).value = format(value);
    document.getElementById(
      poseComparisonThreshold[sliderType]
      .displayElementId).innerText = format(value);
  };

  const setFaceShoulderValues = (faceValue, shoulderValue) => {
    face.value = faceValue;
    document.getElementById(
      face.displayElementId).innerText = format(face.value);
    shoulder.value = shoulderValue;
    document.getElementById(
      shoulder.displayElementId).innerText = format(shoulder.value);
  };

  const {
    face,
    shoulder,
    faceAndShoulder,
    tilt
  } = poseComparisonThreshold;

  if (initial) {
    updateRangeSliderValues(
      'face', deflate(poseComparisonThreshold.face.value));
    updateRangeSliderValues(
      'shoulder', deflate(poseComparisonThreshold.shoulder.value));
    updateRangeSliderValues(
      'faceAndShoulder', deflate(poseComparisonThreshold.face.value));
  } else {
    if (!overrideFaceAndShoulder) {
      setFaceShoulderValues(
        document.getElementById(face.elementId).value,
        document.getElementById(shoulder.elementId).value
      );
    } else {
      const faceShoulderValue = document.getElementById(
        faceAndShoulder.elementId
      ).value;
      setFaceShoulderValues(faceShoulderValue, faceShoulderValue);
      document.getElementById(
        faceAndShoulder.displayElementId
      ).innerText = format(faceShoulderValue);
      updateRangeSliderValues('face', faceShoulderValue);
      updateRangeSliderValues('shoulder', faceShoulderValue);
    }
    tilt.value = inflate(face.value);
    face.value = inflate(face.value);
    shoulder.value = inflate(shoulder.value);
  }
};

/**
 * Adds methods to listen to the change event that happens on
 * the slider elements
 */
const setupRangeSliderListeners = () => {
  document.getElementById(
    poseComparisonThreshold.face.elementId
  ).oninput = (ev) => {
    updateThresholdValue();
  };
  document.getElementById(
    poseComparisonThreshold.shoulder.elementId
  ).oninput = () => {
    updateThresholdValue();
  };
  document.getElementById(
    poseComparisonThreshold.faceAndShoulder.elementId
  ).oninput = () => {
    updateThresholdValue(false, true);
  };
};

const showHideDiv = (div, show = true) => {
  div.style.display = show === true ? 'block' : 'none';
};

/**
 * Starts up the camera feed, sets the canvas width and height,
 *     attaches the baseline image capture event.
 */
const startupBaselineCameraFeed = () => {
  baselineVideo = document.getElementById('baseline-video');
  baselineCanvas = document.getElementById('baseline-canvas');
  takeBaselinePhotoBtn = document.getElementById('takeBaselinePhotoBtn');

  navigator.getMedia = (navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia);
  permissionsManager.requestCameraPermission(baseLineImageWidth, baseLineImageHeight, baselineVideo)

  baselineVideo.addEventListener('canplay', (ev) => {
    if (!streaming) {
      baseLineImageHeight = (
        baselineVideo.videoHeight /
        (baselineVideo.videoWidth / baseLineImageWidth)
      );

      if (isNaN(baseLineImageHeight)) { // Due to firefox bug.
        baseLineImageHeight = baseLineImageWidth / (4 / 3);
      }

      baselineVideo.setAttribute('width', baseLineImageWidth);
      baselineVideo.setAttribute('height', baseLineImageHeight);
      baselineCanvas.setAttribute('width', baseLineImageWidth);
      baselineCanvas.setAttribute('height', baseLineImageHeight);
      streaming = true;
    }
  }, false);

  takeBaselinePhotoBtn.addEventListener('click', (ev) => {
    takeBaselinePhoto();
    showHideDiv(baselineDiv, false);
    showHideDiv(actualDiv, true);
    ev.preventDefault();
  }, false);

  clearBaselinePhoto();
};

/**
 * Clears the baseline image from the canvas.
 */
const clearBaselinePhoto = () => {
  let context = baselineCanvas.getContext('2d');
  context.fillStyle = '#AAA';
  context.fillRect(0, 0, baselineCanvas.width, baselineCanvas.height);
};

/**
 * Grabs the current fame and processes that as the baseline image.
 */
const takeBaselinePhoto = () => {
  const ctx = baselineCanvas.getContext('2d');
  ctx.lineWidth = 2;
  if (baseLineImageWidth && baseLineImageHeight) {
    baselineCanvas.width = baseLineImageWidth;
    baselineCanvas.height = baseLineImageHeight;
    ctx.clearRect(0, 0, baseLineImageWidth, baseLineImageHeight);
    ctx.save();
    ctx.scale(-1, 1);
    ctx.translate(-baseLineImageWidth, 0);
    ctx.drawImage(
      baselineVideo, 0, 0, baseLineImageWidth, baseLineImageHeight
    );
    ctx.restore();
    extractModelOutputs(baselineVideo, ctx, startDetection);
  } else {
    clearBaselinePhoto();
  }
  startCountdownTimer();
};

/**
 * Extracts the key points from an image object
 *  Represents the points on the canvas
 *  Connects the adjacents points on the canvas to draw out the skeleton.
 * Accepts onComplete which is the callback method to be executed after
 *  the keypoints have been extracted.
 */
async function extractModelOutputs(image, ctx, onComplete) {
  if (net) {
    const params = estimationParams;
    const modelOutputs = await net.estimateSinglePose(
      image, params.imageScaleFactor, params.flipHorizontal, params.outputStride
    );
    baselineModelOutputs = Object.assign({}, modelOutputs);
    const {
      keypoints,
    } = baselineModelOutputs;
    drawKeypoints(keypoints, params.minPartConfidence, ctx);
    drawSkeleton(keypoints, params.minPartConfidence, ctx);
    onComplete();
  }
};

/**
 * Grabs the current frame from the video and extracts the keypoints.
 * Functionality is similar to extractModelOutputs except that it runs
 * on video and calls itself to run on the next frame.
 * @param {*} video
 */
async function detectPoseFromActiveVideo(video) {
  const canvas = document.getElementById('actual-output-canvas');
  const ctx = canvas.getContext('2d');
  ctx.lineWidth = 2;
  canvas.width = baseLineImageWidth;
  canvas.height = baseLineImageHeight;
  const params = estimationParams;

  async function runPoseDetection() {
    if (detectPoseInRealTime === true) {
        const MAXIMUM_POSE_DETECTIONS = 1;
        const SCORE_THRESHOLD = 0.5;
        const NMS_RADIUS = 20;
        const pose = (await net.estimateMultiplePoses(
        video, params.imageScaleFactor,
        params.flipHorizontal, params.outputStride,
        MAXIMUM_POSE_DETECTIONS, SCORE_THRESHOLD, NMS_RADIUS
        ))[0];

      if(pose == undefined) {
        alert('Restarting Your Session Since Your Face or Body Went Out of Range!')
        location.reload();
      }

      ctx.clearRect(0, 0, baseLineImageWidth, baseLineImageHeight);
      ctx.save();
      ctx.scale(-1, 1);

      ctx.translate(-baseLineImageWidth, 0);
      ctx.drawImage(video, 0, 0, baseLineImageWidth, baseLineImageHeight);
      ctx.restore();
      if (baselineModelOutputs) {
        const {
          keypoints,
        } = pose;

        drawKeypoints(keypoints, params.minPartConfidence, ctx);
        drawSkeleton(keypoints, params.minPartConfidence, ctx);
        displayPoseMovementResult(comparePoses(pose, baselineModelOutputs));
      }
    } else {
      ctx.clearRect(0, 0, baseLineImageWidth, baseLineImageHeight);
    }
    posDetectIntervalId = setTimeout(runPoseDetection, posDetectionInterval);
  }

  runPoseDetection();
};

const writeResultToHtmlList = (obj, listId) => {
  let list = document.getElementById(listId);
  list.innerHTML = '';
  for (let key in obj) {
    if (obj[key]) {
      const {
        x,
        y,
      } = obj[key]['differenceValue'];
      const liNode = document.createElement('li');
      const textNode = document.createTextNode(
        `${key}: X: ${Number(x).toFixed(1)}, Y: ${Number(y).toFixed(1)}`
      );
      liNode.appendChild(textNode);
      list.appendChild(liNode);
    }
  };
};

const sendPostureStatus = (status) => {
  if (window.postMessage) {
    status = Object.assign({type: 'POSTURE_STATUS'}, status);
    window.postMessage(status, '*')
  }
  postureNotifier.showNotification(status)
}
/**
 * Using the passed in poseMovementObj object, this method updates the list dom
 *     displaying the current results of the pose comparison.
 * It also displays warnings / info based on the thresholds set.
 */
let warningDetection = {
  detected: false,
  timeDetected: null,
};
const displayPoseMovementResult = (poseMovementObj) => {
  const displayWarning = (warningMessage = 'You moved') => {
    const calculatedWaitTime = (new Date()) - (warningDetection.timeDetected);
    if (
      warningDetection.detected &&
      calculatedWaitTime >= waitTimeForFalseDetection * 1000) {
      // movementResultText.innerText = warningMessage;
      actualTriggerDiv.style.backgroundColor = '#DC514E';
      warningDetection.detected = false;
      warningDetection.timeDetected = null;
      sendPostureStatus({
        'status': 'BAD'
      });
    } else {
      if (warningDetection.detected && warningDetection.timeDetected) {
        return;
      }
      warningDetection.detected = true;
      warningDetection.timeDetected = new Date();
    }
  };

  const shouldDisplayWarning = (obj) => {
    for (let key in obj) {
      if (obj[key]) {
        if (obj[key].exceedsThreshold === true) {
          return true;
        }
      }
    }
  };

  const {
    face,
    shoulder,
    tilt,
  } = poseMovementObj;

  // writeResultToHtmlList(face, faceResultDivId);
  // writeResultToHtmlList(shoulder, shoulderResultDivId);
  // writeResultToHtmlList(tilt, tiltResultDivId);

  const warnings = {
    'shoulders': shouldDisplayWarning(shoulder),
    'neck': shouldDisplayWarning(face),
    'leaning': shouldDisplayWarning(tilt),
  };

  let warningTexts = [];

  for (let key in warnings) {
    if (warnings[key]) {
      warningTexts.push(key);
    }
  }

  if (warningTexts.length > 0) {
    displayWarning(`Please check your ${warningTexts.join(', ')}`);
    return;
  }

  warningDetection.detected = false;
  warningDetection.timeDetected = null;
  // movementResultText.innerText = 'Posture Looking OK!';
  actualTriggerDiv.style.backgroundColor = '#91ce9f';
  sendPostureStatus({
    'status': 'OK'
  });
};

/**
 * Takes two poses and returns the % difference between the X and Y
 *     coordinates of all the keypoints.
 */
const comparePoses = (pose, baselinePose) => {
  let result = {};
  const {
    keypoints,
  } = pose;
  const baselineKeypoints = baselinePose.keypoints;
  for (let k in keypoints) {
    if (keypoints[k]) {
      const {
        part,
        position,
        score,
      } = keypoints[k];
      const baselinePosition =
        baselineKeypoints[k] && baselineKeypoints[k].position;
      result[part] = {
        y: (
          (
            (baselinePosition.y - position.y) / baselinePosition.y
          ) * maxThreshold
        ),
        x: (
          (
            (baselinePosition.x - position.x) / baselinePosition.x
          ) * maxThreshold
        ),
        confidence: score,
      };
    }
  }

  const compareDifferenceWithThreshold = (obj, result, type) => {
    if (type === 'tilt') {
      let returnObj = {};
      const partsToCompare = [
        ['eye', 'leftEye', 'rightEye'],
        ['ear', 'leftEar', 'rightEar'],
        // ['shoulder', 'leftShoulder', 'rightShoulder'],
      ];
      for (let i in partsToCompare) {
        if (partsToCompare[i]) {
          const [part, left, right] = partsToCompare[i];
          const x = Math.abs(result[left].x);
          const xx = Math.abs(result[right].x);
          const z = Math.abs(result[left].x - result[right].x);
          const y = Math.abs(result[left].y);
          returnObj[part] = {
            differenceValue: {
              x,
              y,
            },
            exceedsThreshold: (x > poseComparisonThreshold.tilt.value ||
                               xx > poseComparisonThreshold.tilt.value ||
                               z > poseComparisonThreshold.tilt.value/3.5),
          };
        }
      }
      return returnObj;
    }

    for (let key in obj) {
      if (obj[key]) {
        const {
          x,
          y,
          confidence,
        } = result[key];
        obj[key] = {
          differenceValue: {
            y,
            x,
          },
          exceedsThreshold:
            (y < 0) ?
            Math.abs(y) > (poseComparisonThreshold[type].value * 1.2) : false,
          confidence,
        };
      }
    }
    return obj;
  };

  poseMovementObj.face = compareDifferenceWithThreshold(
    poseMovementObj.face, result, 'face'
  );
  poseMovementObj.shoulder = compareDifferenceWithThreshold(
    poseMovementObj.shoulder, result, 'shoulder'
  );
  poseMovementObj['tilt'] = compareDifferenceWithThreshold(
    Object.assign({}, poseMovementObj.face, pose.shoulder), result, 'tilt'
  );
  return poseMovementObj;
};

/**
 * Does a check for baseline photo and calls detectPoseFromActiveVideo method.
 */
const startDetection = () => {
  if (!baselineModelOutputs) {
    const message = 'Take baseline photo first';
    console.error(message);
    alert(message);
    return;
  }
  detectPoseInRealTime = true;
  detectPoseFromActiveVideo(baselineVideo);
};

/**
 * Updates the flag that determines if the detectPoseFromActiveVideo
 *     should keep running.
 */
const stopDetection = () => {
  detectPoseInRealTime = false;
};


/**
 * Timer functions
 */
const stopCountdownTimer = () => {
  clearInterval(countdownIntervalId);
  countdownTime = DEFAULT_TIMEOUT_DURATION;
}

const startCountdownTimer = () => {
  const getFormattedTime = (time) => {
    time = (time == 0 ? '00': time);
    time = '' + time;
    if (time.length == 1) {
      time = '0' + time;
    }
    return time;
  }

  countdownTime = countdownTime - 1;
  let minutes = Math.floor(countdownTime / 60);
  let seconds = countdownTime % 60;
  let timeLeft = getFormattedTime(minutes) + ":" + getFormattedTime(seconds);
  timerDiv.innerText = timeLeft;
  // minutesDiv.innerText = getFormattedTime(Math.floor(DEFAULT_TIMEOUT_DURATION / 60) + ":" +  getFormattedTime('00'));
  if (countdownTime < 0) {
    stopCountdownTimer();
    alert(timeoutMessage);
    resetBaselinePhoto();
  } else {
    countdownIntervalId = setTimeout(startCountdownTimer, 1000);
  }
}

/**
 * Clears the baseline photo, stops the detection if it is currently running.
 * Sets the stage for capturing another baseline image.
 */
const resetBaselinePhoto = () => {
  clearInterval(posDetectIntervalId);
  stopCountdownTimer();
  writeResultToHtmlList({}, faceResultDivId);
  writeResultToHtmlList({}, shoulderResultDivId);
  writeResultToHtmlList({}, tiltResultDivId);
  stopDetection();
  baselineModelOutputs = null;
  showHideDiv(baselineDiv, true);
  showHideDiv(actualDiv, false);
};

const setupWindowFocusListeners = () => {
  window.onblur = () => {
    posDetectionInterval = DETECTION_INTERVAL_SLOW;
  }
  window.onfocus = () => {
    posDetectionInterval = DETECTION_INTERVAL_FAST;
  }
}
/**
 * Method that is run after the page is loaded.
 * This method starts up other onStartup functions.
 * Also, loads the posenet engine.
 */
async function startup() {
  showHideDiv(actualDiv, false);
  showHideDiv(sessionStats, false);
  showHideDiv(sessionSettingsDiv, false);
  updateThresholdValue(true);
  setupRangeSliderListeners();
  setupWindowFocusListeners();
  net = await posenet.load(posnetMultiplier);
  startupBaselineCameraFeed();
  const btnStopPoseDetection = document.getElementById('stopPoseDetection');
  btnStopPoseDetection.addEventListener('click', (ev) => {
    resetBaselinePhoto();
  }, false);
  const btnResetBaselinePhoto =
    document.getElementById('resetBaselinePhotoBtn');
  btnResetBaselinePhoto.addEventListener('click', (ev) => {
    resetBaselinePhoto();
  }, false);
}

startup();
